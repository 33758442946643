<template>
    <div class="quick-links">
        <router-link v-if="home" to="/" class="eyebrow">Home</router-link>
        <router-link v-if="resume" to="/resume">Resume</router-link>
        <a v-if="arena" href="https://www.are.na/miles-gilbert" target="_blank">Are.na</a>
        <a v-if="contact" href="mailto:miles.gilbert@gmail.com">Contact</a>
        <router-link v-if="blog" to="/blog">Blog</router-link>
    </div>
</template>

<script>
export default {
    name: 'QuickLinks',
    props: {
        resume: Boolean,
        arena: Boolean,
        contact: Boolean,
        home: Boolean,
        downloadResume: Boolean,
        blog: Boolean
    }
}
</script>

<style>
.quick-links {
  margin-top: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.quick-links .material-icons, .quick-links .arena-logo {
  font-size: 18px;
  margin-right: 6px;
}
.quick-links a {
  font-family: 'IBM Plex Sans';
  margin-right: 16px;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 4px 16px;
  border-radius: 20px;
}
.arena-logo {
  letter-spacing: -5px;
}
</style>