<template>
  <div class="wrapper">
    <router-view v-slot="{Component}">
      <transition name="slide">
        <component :is="Component"/>
      </transition>
    </router-view>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
  },
  mounted() {
  },
  created() {
  },
  beforeMount() {
  }
}
</script>


<style>
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Two+Tone');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,300;0,400;0,600;1,700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,wght@0,300;0,400;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200;300;400;700&family=IBM+Plex+Serif:wght@300;400;700&family=Roboto+Serif:ital,opsz,wght@0,8..144,300;0,8..144,400;1,8..144,300;1,8..144,400&display=swap');
#app, body {
  margin: 0;
  font-family: 'IBM Plex Serif', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  font-size: 14px;
  background-color: #F4F4F6;
  line-height: 26px;
  font-smooth: always;
}
a, a:visited, a:active {
  text-decoration: none;
  color: #D14200;
  border-bottom: 1px solid #D14200;
  transition: all 0.1s linear;
}
a:hover {
  color: #D14200;
}
p {
  line-height: 28px;
  font-size: 20px;
}
h1, h2 {
  font-weight: 400;
  letter-spacing: -0.01em;
  margin: 4px 0;
  font-family: 'IBM Plex Serif';
}
h3 {
  font-size: 20px;
  letter-spacing: -0.01em;
  margin: 0;
  font-weight: 400;
}
ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}
.wrapper {
  width: 100vw;
  margin: 0 auto;
  text-align: left;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  box-sizing: border-box;
}
.text-light {
  font-weight: 400;
  color: #66666E;
}
.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.button {
  background-color: #000000;
  padding: 8px 12px;
  border-radius: 4px;
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
}
.button:hover {
  background-color: #111;
}
.button.text-only {
  background-color: transparent;
}
.blur-bg {
  background-color: rgba(0, 0, 0, 0.199);
  backdrop-filter: blur(6px);
}
@media screen and (min-width: 900px) {
  .wrapper {
    margin: 0 auto;
  }
  .custom-scrollbar::-webkit-scrollbar {
    width: 0px;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb { /* Foreground */
    background: rgb(22, 22, 22);
    border-radius: 4px;
  }
  .custom-scrollbar::-webkit-scrollbar-track { /* Background */
    background: #000;
  }
  a:hover {
    color: #000000;
  }
}

.slide-fade-enter-active, .slide-down-fade-enter-active, .slide-left-fade-enter-active {
  transition: all 0.6s cubic-bezier(.32,.69,.44,1);
}

.slide-fade-leave-active, .slide-down-fade-leave-active, .slide-left-fade-leave-active {
  transition: all 0.3s cubic-bezier(.32,.69,.44,1);
}

.slide-down-fade-move, .slide-down-fade-enter-active, .slide-down-fade-leave-active {
  position: absolute;
}

.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 1;
}

.slide-fade-leave-to {
  transform: translateY(20px) scale(90%);
  opacity: 0;
}

.slide-down-fade-enter-from {
  transform: translateY(-20px);
  opacity: 1;
}

.slide-down-fade-leave-to {
  transform: scale(90%);
  opacity: 0;
}

.slide-left-fade-enter-from {
  transform: translateX(20px);
  opacity: 1;
}

.slide-left-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.slide-enter-active {
  transition: all 0.6s cubic-bezier(.32,.69,.44,1);
}

.slide-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-enter-from, .slide-leave-to {
  transform: translateY(60px);
  opacity: 0;
}

.fade-move, .fade-enter-active {
  transition: all 0.1s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.fade-move, .fade-leave-active {
  transition: all 0.1s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}



.fade-slideout-enter-active {
  transition: all 1s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.fade-slideout-leave-active {
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.fade-slideout-enter-from {
  opacity: 1;
  transform: translateY(60px);
}
.fade-slideout-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}
@media screen and (min-width: 900px) {
  .wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }
  .header {
    max-width: 500px;
  }
}
</style>
