<template>
  <router-link v-if="project.active" :to="'/view?project=' + project.path" class="project-card">
        <img :src="GetAssetPath(project.images.thumb)">
        <div class="description">
          <div class="left">
            <h2>{{project.title}}</h2>
            <h3>{{project.shortDescription}}</h3>
          </div>
          <div class="right">
            
          </div>
        </div>
  </router-link>
</template>

<script>
export default {
    props: {
        project: Object
    },
    methods: {
        GetAssetPath(path) {
            return require('@/assets/thumbs/' + path)
        }
    }
}
</script>

<style>
a, a:active, a:visited {
  border-bottom: none;
}
.project-card {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}
.project-card .description {
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.description .material-icons {
  color: #D14200;
  font-size: 26px;
}
.project-card img {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 0 15px 2px #d3d3d3;
}
.description .right {
  margin-left: 50px;
}
h3 {
  color: black;
}
</style>