<template>
    <div v-if="$route.query.project" class="footer">
      <div class="inner">
        <p class="text-light eyebrow">More projects</p>
        <ul class="project-list">
          <li v-for="(project, index) in this.$store.state.projects" :key="index">
            <router-link
            v-if="project.active"
            @click.prevent="LoadProject(project.path)"
            :to="'/view?project=' + project.path"
            :class="{disabled: currentProject.path === project.path}">
            {{project.title}}
            </router-link>
          </li>
        </ul>
        <QuickLinks :home="true" :contact="true" :arena="true" :resume="true" />
      </div>
    </div>
</template>

<script>
import QuickLinks from '@/components/QuickLinks.vue'
export default {
  name: 'Footer',
  components: {
    QuickLinks
  },
  props: {
    currentProject: Object
  },
  data() {
    return {
      currentPath: null
    }
  },
  beforeCreate() {
    if (this.$route.query.pathname) {
      this.currentPath = this.$route.query.project
    } else {
      this.currentPath = window.location.pathname
    }
    console.log(this.currentPath)
  },
  methods: {
    LoadProject(path) {
      window.scrollTo(0,0)
      this.$emit('load-project', path)
      this.CheckPath()
    },
    CheckPath() {
      this.currentPath = this.$route.query.project
    }
  }
}
</script>

<style>
.footer {
  margin-top: 0px;
  width: 100%;
  grid-area: 2 / 1 / 3 / 1;
  height: 150px;
  font-size: 2em;
  line-height: 1.5em;
  font-weight: 300;
}
.eyebrow {
  font-family: 'IBM Plex Sans';
  color: #000;
  font-size: 16px;
}
.footer .inner {
    padding: 20px 20px 80px 20px;
}
a.disabled {
  color: #000;
  pointer-events: none;
}
</style>