<template>
  <div class="home">
    <div class="header">
      <h1>Miles Gilbert</h1>
      <h2 class="text-light text-serif">Index of projects</h2>
      <p>I'm a creative director and hobbyist web developer.</p>
      <p>My skills and experience span a wide range of disciplines, from global marketing campaigns to product design.</p>
      <p class="status text-light">Currently at Indeed Creative<br/>Previously at R/GA, T3, BBDO, and Ogilvy</p>
      <QuickLinks :resume="true" :arena="true" :contact="true" :blog="true"/>
    </div>
    <div class="project-list">
      <ProjectCard v-for="(project, index) in this.$store.state.projects" :key="index" :project="project"/>
    </div>
    <Colophon />
  </div>
</template>

<script>
import ProjectCard from '@/components/ProjectCard'
import Colophon from '@/components/Colophon'
import QuickLinks from '@/components/QuickLinks.vue'
export default {
  name: 'Home',
  components: {
    ProjectCard, Colophon, QuickLinks
  }
}
</script>

<style scoped>
.home {
  grid-area: 1 / 1 / 3 / 1;
}
.status {
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  line-height: normal;
}
.header {
  margin: 60px 0;
  padding: 0 20px;
  box-sizing: border-box;
  max-width: 550px;
}
.project-list {
  display: grid;
  grid-gap: 60px;
  grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
  margin-bottom: 60px;
}
@media screen and (min-width: 900px) {
}
</style>
