import { createStore } from 'vuex'

export default createStore({
  state: {
    projects: {
      metitate: {
        title: 'Metitate.art',
        shortDescription: 'Slow down, look at art',
        images: {
          thumb: 'metitate.jpg',
          showcase: {
            0: {
              path: 'metitate2.jpg',
              type: 'image'
            },
            1: {
              path: 'metitate3.jpg',
              type: 'image'
            },
            2: {
              path: 'metitate4.jpg',
              type: 'image'
            },
            3: {
              path: 'metitate5.jpg',
              type: 'image'
            },
            4: {
              path: 'metitate6.jpg',
              type: 'image'
            },
            5: {
              path: 'metitate7.jpg',
              type: 'image'
            }
          }
        },
        path: 'metitate',
        year: 2022,
        role: 'Developer, Designer',
        client: 'Self directed',
        description: '<p>Started as an app designed to encouraged me to slow down and get away from doom scrolling, Metitate.art evolved into a powerful and fun tool to explore the collection of one of my favorite museums: The Metropolitan Museum of Art.</p><p>Every day, a new gallery is presented to anyone who opens the app. From European classics to ancient sarcophagi, objects are presented in extreme detail thanks to a powerful API supplied by The Met. And, thanks to an algorithm that crunches data such as age, materials used, and the reknown of the artist, the most interesting objects are seen first.</p><p>Registered users can collect objects by adding them to their personal collection. And, once an object is collected, no one else can claim it.</p><p>Try it at <a href="https://www.metitate.art">metitate.art</a></p>',
        active: true
      },
      ibmwatson: {
        title: 'IBM Watson',
        shortDescription: 'An identity for artificial Intelligence',
        images: {
          thumb: 'watson.jpg',
          showcase: {
            0: {
              path: '20963502',
              type: 'vimeo',
              caption: 'We used color, speed, and shape to convey a sense of data, intelligence, and even emotion. The goal of the avatar was to show off the intelligence of Watson while avoiding association with the decades of evil computers that appear in popular culture.'
            },
            1: {
              path: 'watson-states.mp4',
              type: 'video'
            },
            2: {
              path: '22044803',
              type: 'vimeo'
            },
            3: {
              path: '19833627',
              type: 'vimeo',
              caption: 'Part of a larger documentary series, "The Face of Watson" revealed process of creating Watson\'s voice, face, and stage presence.'
            },
          }
        },
        path: 'ibmwatson',
        year: 2011,
        role: 'Art Director',
        client: 'Ogilvy for IBM',
        description: '<p>Over the course of two years, I was part of the core team that developed the original identity for IBM\'s natural language processing computer, named Watson.</p><p>The identity\'s brilliant mix of design and code makes this one of my favorite projects to revisit when concepting future interactive experiences.</p><p>Created in collaboration with <a href="https://joshuadavis.com/" target="_blank">Joshua Davis</a>.</p>',
        active: true
      },
      indeedrisingvoices: {
        title: 'Indeed Rising Voices',
        shortDescription: 'A monument dedicated to the perseverance of BIPOC filmmakers',
        images: {
          thumb: 'risingvoices.jpg',
          showcase: {
            0: {
              path: 'chairs4.jpg',
              type: 'image'
            },
            1: {
              path: 'chairs3.jpg',
              type: 'image'
            },
            2: {
              path: 'screening1.jpg',
              type: 'image'
            },
            3: {
              path: 'poster2.jpg',
              type: 'image'
            }
          }
        },
        path: 'indeedrisingvoices',
        year: 2021,
        role: 'Associate Creative Director',
        client: 'Indeed Creative',
        description: '<p>Inspired by the sculptures of Ai Wei Wei, the Rising Voices installation...</p>',
        active: false
      },
      theendoftheroad: {
        title: 'The End of the Road',
        shortDescription: 'A photographic study on the limitations of digital maps',
        images: {
          thumb: 'theendoftheroad.jpg',
          showcase: {
            0: {
              path: 'theendoftheroad01.jpg',
              type: 'image'
            },
            1: {
              path: 'theendoftheroad02.jpg',
              type: 'image'
            },
            2: {
              path: 'theendoftheroad03.jpg',
              type: 'image'
            },
            3: {
              path: 'theendoftheroad04.jpg',
              type: 'image'
            }
          }
        },
        path: 'theendoftheroad',
        year: 2012,
        role: 'Researcher',
        client: 'Self directed',
        description: '<p>The End of the Road was a self-directed interrogation of the limits of the Google Streetview project conducted sporadically from 2008 – 2010. The project explored the tension between the unlimited potential of big data and the very limited realities of physical space by documenting where the Google Streetview car had to physically stop driving and, as a result, stop capturing images. Obstacles such as closed fences, unpassable dirt roads, and even tiny dogs showed how our physical world can obstruct our virtual world.</p><p>The project ended in 2011 when, unsurprisingly, Google designed a workaround for these obstacles by including user submitted photos and who knows what else to stitch together unobstructed images of our world. Still, the project lives on as a reminder of limits of technology and the value of privacy – both as an individual right and a corporate commodity.</p><p>View the project on <a href="https://www.are.na/miles-gilbert/the-end-of-the-road" target="_blank">Are.na</a></p>',
        active: true
      },
      indeedjobmarket: {
        title: 'Indeed Job Market',
        shortDescription: 'A jobseeker-first job market',
        images: {
          thumb: 'indeedjobmarket.jpg',
          showcase: {
            0: {
              path: 'overview.mp4',
              type: 'video',
              caption: 'Project overview'
            },
            1: {
              path: 'event_17.jpg',
              type: 'image'
            },
            2: {
              path: 'event_10.jpg',
              type: 'image'
            },
            3: {
              path: 'event_1.jpg',
              type: 'image'
            },
            4: {
              path: 'event_2.jpg',
              type: 'image'
            },
            5: {
              path: 'event_4.jpg',
              type: 'image'
            },
            6: {
              path: 'overview2.mp4',
              type: 'video'
            },
            7: {
              path: 'event_13.jpg',
              type: 'image'
            },
            8: {
              path: 'event_14.jpg',
              type: 'image'
            },
            9: {
              path: 'event_6.jpg',
              type: 'image'
            },
            10: {
              path: 'event_7.jpg',
              type: 'image'
            },
            11: {
              path: 'event_16.jpg',
              type: 'image'
            },
            12: {
              path: 'event_15.jpg',
              type: 'image'
            },
          }
        },
        path: 'indeedjobmarket',
        year: 2020,
        role: 'Associate Creative Director',
        client: 'Indeed Creative',
        description: '<p>Working closely with the in-house team at Indeed, I guided the design and execution of the Indeed Job Market - a series of 8 activations around the United States that sought to reimagine the job fair for the modern age.</p><p>Work included the design of event collateral from cocktail napkins to large scale murals; selecting furniture and defining the layout of the space; and creating a social media campaign to promote it all.</p>',
        active: true
      },
      lowesfixinsix: {
        title: 'Lowe\'s Fix in Six',
        shortDescription: 'A Vine legend',
        images: {
          thumb: 'lowesfixinsix.jpg',
          showcase: {
            0: {
              path: 'lowesvine01.mp4',
              type: 'video',
              caption: 'Frozen pipes are bad news. Turn on faucets, shut off water supply, then heat pipes with hair dryer until water flows.'
            },
            1: {
              path: 'lowesvine02.mp4',
              type: 'video',
              caption: 'Use walnuts to remove light scratches on wood. The oils will fill in the scratches.'
            },
            2: {
              path: 'lowesvine03.mp4',
              type: 'video',
              caption: 'Turn a colorful or patterned tank top into a pillowcase in 5 minutes.'
            },
            3: {
              path: 'lowesvine04.mp4',
              type: 'video',
              caption: 'Keep your rosebush strong and healthy with help from a banana peel.'
            },
            4: {
              path: 'lowesvine05.mp4',
              type: 'video',
              caption: 'Make shoveling easier! Spray nonstick cooking oil to your shovel and prevent snow from sticking.'
            },
            5: {
              path: 'lowesvine06.mp4',
              type: 'video',
              caption: 'To keep a used paintbrush fresh for up to 4 days, seal it in a bag and place in the fridge.'
            },
            6: {
              path: 'lowesvine07.mp4',
              type: 'video',
              caption: 'To remove a water stain from wood, spread real mayo on it and let sit for 4+ hours.'
            },
            7: {
              path: 'lowesvine08.mp4',
              type: 'video',
              caption: 'When building a deck, use a handy screw or 16D nail to ensure proper board spacing.'
            }
          }
        },
        path: 'lowesfixinsix',
        year: 2013,
        role: 'Art Director',
        client: 'BBDO for Lowe\'s',
        description: '<p>The Fix in Six campaign was a huge success for Lowe\'s and the company wanted to build on that success with a second "season" of videos that were more complex and memorable. I was part of a small team brought in to create this round of videos. We worked with a fantastic team of animators, puppeteers, and stop motion directors to bring these 6 second worlds to life.</p>',
        layout: 'grid',
        active: true
      },
      workhappinessseries: {
        title: 'Work Happiness Series',
        shortDescription: 'Illustrating the modern philosophy of happiness at work',
        images: {
          thumb: 'workhappinessseries.jpg',
          showcase: {
            0: {
              path: '527536044',
              type: 'vimeo',
              caption: 'Overview'
            },
            1: {
              path: '527537297',
              type: 'vimeo',
              caption: 'Purpose'
            },
            2: {
              path: '527538673',
              type: 'vimeo',
              caption: 'Connections'
            },
            3: {
              path: '527539686',
              type: 'vimeo',
              caption: 'Flow'
            },
            4: {
              path: '527540796',
              type: 'vimeo',
              caption: 'Growth'
            },
            5: {
              path: '527541816',
              type: 'vimeo',
              caption: 'Job Crafting'
            }
          }
        },
        path: 'workhappinessseries',
        year: 2020,
        role: 'Associate Creative Director',
        client: 'Indeed Creative',
        description: '<p>The Work Happiness Series provides an overview of the various dimensions of happiness that Indeed uncovered over the course of a 3 year long study into how people feel in the workplace. My work began with reviewing the interviews with experts and concepting motion graphics that illustrate their words. These videos became one of the first pieces of content to feature Indeed\'s new illustration style and brand guidelines, which I helped develop alongside the great in-house designers and illustrators at Indeed.</p>',
        active: true
      },
      shooot: {
        title: 'Shooot',
        shortDescription: 'A camera for happy accidents',
        images: {
          thumb: 'shooot.jpg',
          showcase: {
            0: {
              path: 'splash2.jpg',
              type: 'image',
            },
            1: {
              path: 'screen01.jpg',
              type: 'image',
            },
            2: {
              path: 'screen02.jpg',
              type: 'image',
            },
            3: {
              path: 'screen03.jpg',
              type: 'image',
              caption: 'The design of the app was based on simplicity so that users could focus on the world around them and capture it without distraction.'
            },
            4: {
              path: 'screen04.mp4',
              type: 'video',
            },
            5: {
              path: 'screen05.jpg',
              type: 'image',
            },
            6: {
              path: 'onboard1.jpg',
              type: 'image',
              caption: 'Because of the unconventional nature of the app, we shipped it with a preloaded tutorial that served two purposes: teach users how to use the app and get them onboard with its ethos of letting go.'
            },
            7: {
              path: 'onboard2.jpg',
              type: 'image',
            },
            8: {
              path: 'onboard3.jpg',
              type: 'image',
            },
            9: {
              path: 'onboard4.jpg',
              type: 'image',
            },
          }
        },
        path: 'shooot',
        year: 2016,
        role: 'Co-creator, Product Designer',
        client: 'Self directed',
        description: '<p>Shooot was a camera app for iPhone that removed the distractions of smartphone photography. The app simulated the waiting period of old school film development by asking users to wait until the next morning to see their photos. Shooot also encouraged users to work without a viewfinder – helping them see the world through their own eyes. We called it "The Camera for Happy Accidents".</p><p><a href="https://www.instagram.com/explore/tags/shotwithshooot/" target="_blank">#shotwithshooot on Instagram</a></p>',
        active: true
      },
      offtheeatenpath: {
        title: 'Off the Eaten Path',
        shortDescription: 'Meticulously rendered ingredients set in balletic motion',
        images: {
          thumb: 'offtheeatenpath.jpg',
          showcase: {
            0: {
              path: '348449284',
              type: 'vimeo'
            },
            1: {
              path: 'process1.mp4',
              type: 'video',
              inset: true,
              caption: 'Ingredients rendering process'
            },
            2: {
              path: 'process2.mp4',
              type: 'video',
              inset: true,
              caption: 'Ingredients rendering process'
            },
            3: {
              path: 'process3.mp4',
              type: 'video'
            }
          }
        },
        path: 'offtheeatenpath',
        year: 2019,
        role: 'Associate Creative Director',
        client: 'R/GA for Off the Eaten Path',
        description: '<p>We created a simple and elegant product-focused campaign to help launch the Off The Eaten Path family of snacks. Taking cues from the simplicity of the package design, I helped create an impactful visual device that worked seamlessly across many different channels and led to increased viewer engagement for a new brand.</p><p>Work included the creation of 50+ individual 3d models of ingredients like rice and snap peas, casting and recording VO for US and global executions, the creation of a unique mnemonic sound for the brand, and working with 3d animators to put it all together.</p>',
        active: true
      },
      hp: {
        title: 'HP: Make it Matter',
        shortDescription: 'How to bring focus to a disjointed brand',
        images: {
          thumb: 'hp.jpg',
          showcase: {
            0: {
              path: '66922624',
              type: 'vimeo'
            },
            1: {
              path: '53178207',
              type: 'vimeo',
              caption: 'In video, the campaign featured real people using HP technology to explore and share their individual passions.'
            },
            2: {
              path: 'hptouch-01.jpg',
              type: 'image',
              caption: 'In print and photography, the campaign focused on the computer as a touchable tool for creation and highlighted the human side of HP technology.'
            },
            3: {
              path: 'hptouch-02.jpg',
              type: 'image'
            },
            4: {
              path: 'print01.jpg',
              type: 'image'
            },
            5: {
              path: 'print02.jpg',
              type: 'image'
            }
          }
        },
        path: 'hp',
        year: 2012,
        role: ['Senior Art Director'],
        client: 'BBDO for HP',
        description: '<p>HP was a disjointed brand. With hundreds of different campaigns all with vastly different looks and messages launching in 170 countries year after year, the company had lost its voice. We were asked to rebrand the company to create one, unified HP.</p>',
        active: true
      },
      groupchatanalyzer: {
        title: 'Groupchat Analyzer',
        shortDescription: 'A WhatsApp experiment',
        images: {
          thumb: 'groupchatanalyzer.jpg',
          showcase: {
            0: {
              path: 'groupchatanalyzer.jpg',
              type: 'image'
            }
          }
        },
        path: 'groupchatanalyzer',
        year: 2018,
        role: 'Designer, Developer',
        client: 'Self directed',
        description: '<p>I\'m a big fan of the groupchat. I\'m also a big fan of data. So I combined these two things in a little online tool that lets crunch the data behind your WhatsApp groupchats.</p><p>Play with it <a href="https://group-chat-analyzer.glitch.me/">here</a>.</p>',
        active: true
      }
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
